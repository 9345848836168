import { Component, EventEmitter, Inject, Output, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutetracingService } from '../../../services/routetracing.service';
import { Platform } from '@angular/cdk/platform';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-conexathankyou',
  templateUrl: './conexathankyou.component.html',
  styleUrl: './conexathankyou.component.css'
})
export class ConexathankyouComponent {
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();
  private originRoute: string | null = null;
  private previousUrl: string | null = null;
  description: string = '';

  constructor(private router: Router, private routeTracing: RoutetracingService, @Inject(PLATFORM_ID) private platformId: Object) {
    // Capture the previous URL from history
    const previousHistoryEntry = window.history.state.navigationId ? window.history.state.navigationId - 3 : null;
    if (previousHistoryEntry) {
      this.previousUrl = document.referrer;
    }

    let paramString = this.routeTracing.getParamValue();

    if (paramString.path === 'conexa') {
      this.description = 'Conexa'
    
    } else {
      this.description = 'Freight'
    }

  }

  closeModal($event: any) {
    this.router.navigate([{ outlets: { modal: null } }]);
    this.modalClose.next($event);
    const element = document.getElementById('conexaThankyouPopUp');
    element!.remove();

    // Redirect based on the origin route
    let paramString = this.routeTracing.getParamValue();
    if (paramString.path === 'conexa') {

      // this.router.navigate([{ outlets: { modal: null } }]);
      // this.modalClose.next($event);
      // this.router.navigate(['/MyIntercargo/conexa']);
      if (isPlatformBrowser(this.platformId)) {


        window.location.href = '/MyIntercargo/conexa';
      }
    } else {
      if (isPlatformBrowser(this.platformId)) {
        window.location.href = '/';
      }
    }
  }
}